import React from "react";
import ReactDOM from 'react-dom/client';
import {Calculator} from "@matbea/payments"

function renderCalculator(node, props = {}){
  const root = ReactDOM.createRoot(node)
  root.render(React.createElement(Calculator, props))
}
 
window.matbea = {
  renderCalculator
}
